import * as React from "react";
import {
  Box,
  Dialog,
  Flex,
  IconButton,
  Inset,
  ScrollArea,
  TextField,
  VisuallyHidden,
} from "@radix-ui/themes";
import { Cross1Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useConvex } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { toast } from "sonner";
import { SearchResult } from "@repo/convex/convex/search.ts";
import { SearchItem } from "./SearchItem.tsx";
import { useEffect } from "react";
import { useThrottle } from "../common/hooks.ts";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => unknown;
}

export const SearchDialog: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const searchRef = React.useRef<HTMLInputElement>(null);
  const [term, setTerm] = React.useState("");
  const [results, setResults] = React.useState<SearchResult[]>([]);
  const convex = useConvex();
  const throttledTerm = useThrottle(term);

  useEffect(() => {
    if (!throttledTerm) return;
    convex.query(api.search.search, { term: throttledTerm }).then(setResults).catch(toast.error);
  }, [throttledTerm]);

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
      }}
    >
      <Dialog.Content
        aria-describedby={undefined}
        maxWidth={"min(calc(100dvw - 30px), 600px)"}
        align={"start"}
        style={{
          marginTop: "var(--safe-area-inset-top, 0px)",
          overflow: "visible",
          borderRadius: "6px",
        }}
      >
        <VisuallyHidden>
          <Dialog.Title>Search</Dialog.Title>
        </VisuallyHidden>

        <Dialog.Close>
          <IconButton
            style={{ position: "absolute", top: "-10px", right: "-10px", borderRadius: "50%" }}
          >
            <Cross1Icon />
          </IconButton>
        </Dialog.Close>

        <Inset side="top" style={{ backgroundColor: "var(--brown-a4)", padding: "10px" }}>
          <TextField.Root
            ref={searchRef}
            placeholder="Search for anything"
            size={"3"}
            value={term}
            tabIndex={0}
            onChange={(e) => setTerm(e.target.value)}
            autoFocus={true}
          >
            <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
        </Inset>

        <Flex direction={"column"} align={"stretch"} justify={"start"} ml={"-2"} mr={"-4"}>
          {results.length > 0 ? (
            <Box
              style={{
                maxHeight: "calc(100svh - 160px - var(--safe-area-inset-top, 0px))",
                width: "100%",
                overflowY: "auto",
              }}
              onScroll={(e) => {
                searchRef.current?.blur();
              }}
              mt="4"
              pr={"2"}
            >
              <Flex direction={"column"} gap={"4"} align={"stretch"}>
                {results.map((result) => (
                  <SearchItem key={result.item._id} item={result.item} />
                ))}
              </Flex>
            </Box>
          ) : null}
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
