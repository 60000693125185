import * as React from "react";
import { Dialog, IconButton } from "@radix-ui/themes";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { Part1Notes } from "./Part1Notes.tsx";
import { match } from "ts-pattern";
import { Part2UpdateProfile } from "./Part2UpdateProfile.tsx";
import { useOptimisticArchiveItem } from "../../common/hooks.ts";
import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { toast } from "sonner";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { useEffect } from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import { DialogCloseButton } from "../../common/DialogCloseButton.tsx";

interface Props {
  itemId: Id<"items"> | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => unknown;
}

type Parts = "part1Notes" | "part2UpdateProfile";

export const ArchiveItemDialog: React.FC<Props> = ({ isOpen, itemId, setIsOpen }) => {
  const archive = useOptimisticArchiveItem();
  const unarchive = useMutation(api.items.unarchiveMine);
  const [part, setPart] = React.useState<Parts>("part1Notes");
  const item = useQuery(api.items.findMine, itemId ? { itemId } : "skip");

  useEffect(() => {
    setPart("part1Notes");
  }, [itemId]);

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!itemId) return;
        setIsOpen(isOpen);
      }}
    >
      <Dialog.Content
        align={"start"}
        style={{
          marginTop: "calc(var(--safe-area-inset-top, 20px) + 30px)",
        }}
      >
        {match(part)
          .with("part1Notes", () => (
            <Part1Notes itemId={itemId} onNext={() => setPart("part2UpdateProfile")} />
          ))
          .with("part2UpdateProfile", () => (
            <Part2UpdateProfile
              itemId={itemId}
              onPrevious={() => setPart("part1Notes")}
              onClose={() => {
                setIsOpen(false);
                if (!item) return;
                archive({ itemId: item._id })
                  .then(() =>
                    toast.success("Item archived", {
                      action: {
                        label: "Undo",
                        onClick: () => unarchive({ itemId: item._id }).catch(console.error),
                      },
                    }),
                  )
                  .catch(console.error);
              }}
            />
          ))
          .exhaustive()}
        <DialogCloseButton onClick={() => setIsOpen(false)} />
      </Dialog.Content>
    </Dialog.Root>
  );
};
