import * as React from "react";
import { useEffect } from "react";
import { Route } from "type-route";

interface Props {
  to: Route<any>;
}

export const Navigate: React.FC<Props> = React.memo(({ to }) => {
  useEffect(() => {
    const id = setTimeout(() => {
      console.log("Navigating to", to);
      to.replace();
    }, 10);
    return () => {
      clearTimeout(id);
    };
  }, []);

  return null;
});
