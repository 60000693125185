"use client";
import * as React from "react";
import { useEffect } from "react";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { toast, useSonner } from "sonner";

interface Props {}

export const CapacitorUpdatesInterface: React.FC<Props> = ({}) => {
  useEffect(() => {
    CapacitorUpdater.notifyAppReady()
      .then((r) => {
        console.log("App is ready for updates", r);
      })
      .catch((e) => console.error("Error notifying app ready", e));

    CapacitorUpdater.addListener("updateAvailable", (state) => {
      toast.info(`Update available! v${state.bundle.version}`, {
        action: {
          label: "Update Now",
          onClick: () =>
            CapacitorUpdater.set(state.bundle)
              .then(() => CapacitorUpdater.reload())
              .catch((e) => console.error("Error notifying app ready", e)),
        },
        dismissible: false,
        duration: 99999999,
      });
    }).catch(console.error);

    CapacitorUpdater.addListener("updateFailed", (state) => {
      toast.error(`Update failed! v${state.bundle.version}`, {
        action: {
          label: "Try Again",
          onClick: () => CapacitorUpdater.reload().catch(console.error),
        },
      });
    }).catch(console.error);

    // CapacitorUpdater.addListener("downloadComplete", (state) => {
    //   toast.success(`Update downloaded`, {});
    // }).catch(console.error);
    //
    // CapacitorUpdater.addListener("noNeedUpdate", (state) => {
    //   toast.info(`No update needed`, {});
    // }).catch(console.error);

    CapacitorUpdater.addListener("downloadFailed", (state) => {
      toast.warning(`Download failed`, {});
    }).catch(console.error);
  }, []);

  return null;
};
