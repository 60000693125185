import * as React from "react";

interface Props {
  children?: React.ReactNode;
  fontSize?: string;
}

export const AppTitle: React.FC<Props> = ({ children, fontSize = "50px" }) => {
  return (
    <div
      style={{
        fontSize,
        color: "#3a4949",
        fontWeight: "bold",
        lineHeight: "1em",
        letterSpacing: "0.1em",
      }}
    >
      STASH<span style={{ color: "brown" }}>IT</span>
    </div>
  );
};
