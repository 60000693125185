import { ConvexReactClient } from "convex/react";
import { ReactNode, useMemo } from "react";
import { ConvexAuthProvider } from "@convex-dev/auth/react";
import { ConvexQueryCacheProvider } from "convex-helpers/react/cache/provider";

export function ConvexClientProvider({ children, url }: { children: ReactNode; url: string }) {
  const client = useMemo(() => {
    console.log(`Creating ConvexReactClient with url: ${url}`);
    return new ConvexReactClient(url);
  }, [url]);

  return (
    <ConvexAuthProvider client={client}>
      <ConvexQueryCacheProvider>{children}</ConvexQueryCacheProvider>
    </ConvexAuthProvider>
  );
}
