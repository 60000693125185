import * as React from "react";
import { Box, Flex, Heading, ScrollArea, Text } from "@radix-ui/themes";
import { getAppVersion } from "@repo/shared/app/app.ts";
import { PageTopNav } from "../common/PageTopNav.tsx";
import { MyProfileSection } from "./myProfile/MyProfileSection.tsx";
import { DebugSection } from "./DebugSection.tsx";

interface Props {
  children?: React.ReactNode;
}

export const SettingsPage: React.FC<Props> = ({ children }) => {
  return (
    <Flex height={"100%"} direction={"column"}>
      <PageTopNav>
        <Flex align={"stretch"}>
          <Box flexGrow={"1"}>
            <Heading as={"h1"}>Settings</Heading>
          </Box>
        </Flex>
      </PageTopNav>
      <ScrollArea type="auto" scrollbars="vertical" style={{ width: "100%", overflow: "hidden" }}>
        <Flex direction={"column"} p={"5"} pl={"6"} gap={"4"}>
          <Text>Version: {getAppVersion()}</Text>
          <MyProfileSection />
          <DebugSection />
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
