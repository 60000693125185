import * as React from "react";
import { Flex } from "@radix-ui/themes";
import { moreMenuItems } from "../nav.tsx";
import { MoreMenuItem } from "./MoreMenuItem.tsx";

interface Props {}

export const MoreMenuItems: React.FC<Props> = ({}) => {
  return (
    <Flex p={"3"} width={"100%"} gap={"2"} align={"center"} justify={"center"} height={"100%"}>
      {moreMenuItems.map((item, i) => (
        <MoreMenuItem key={i} item={item}>
          {item.label}
        </MoreMenuItem>
      ))}
    </Flex>
  );
};
