import * as React from "react";
import { Box, Portal } from "@radix-ui/themes";
import { MoreMenuItems } from "./MoreMenuItems.tsx";
import { StashItTheme } from "../../../app/StashItTheme.tsx";
import { useRoute } from "../../../routing/router.ts";
import { useEffect } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => unknown;
}

export const MoreMenu: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  // A bit hacky but will do for now
  const route = useRoute();
  useEffect(() => {
    setIsOpen(false);
  }, [route.name]);

  return (
    <Portal>
      <StashItTheme>
        <Box
          position={"fixed"}
          top={"0"}
          left={"0"}
          width={"100dvw"}
          height={"100dvh"}
          data-comment={"MoreMenu"}
          style={{
            pointerEvents: isOpen ? "auto" : "none",
          }}
        >
          <Box
            position={"absolute"}
            top={"0"}
            left={"0"}
            width={"100%"}
            height={"100%"}
            style={{
              background: "rgba(0,0,0,0.5)",
              opacity: isOpen ? 1 : 0,
              transition: "all 0.3s ease",
            }}
            onClick={() => setIsOpen(false)}
          />
          <Box
            position={"absolute"}
            bottom={"0"}
            left={"0"}
            width={"100%"}
            style={{
              backgroundColor: "var(--brown-3)",
              height: "150px",
              opacity: isOpen ? 1 : 0,
              transition: "all 0.2s ease",
              transform: `translateY(${isOpen ? "0" : "50%"})`,
            }}
          >
            <MoreMenuItems />
          </Box>
        </Box>
      </StashItTheme>
    </Portal>
  );
};
