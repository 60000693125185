import * as React from "react";
import { Button, Flex, Table } from "@radix-ui/themes";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { api } from "@repo/convex/convex/_generated/api";
import { useMutation } from "convex/react";
import { toast } from "sonner";
import { formatDistance } from "date-fns";
import { iife } from "@repo/shared/utils/misc.ts";

interface Props {}

export const QueuedUpdatesList: React.FC<Props> = ({}) => {
  const list = useQuery(api.userProfileUpdateQueue.listForMe);
  const startOrQueue = useMutation(api.userProfileUpdateQueue.debugStartOrQueue);

  if (!list) return null;

  return (
    <Table.Root variant="surface" size={"1"}>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>Id</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {list.map((item) => (
          <Table.Row key={item._id}>
            <Table.Cell>{item._id}</Table.Cell>

            <Table.Cell>
              {iife(() => {
                if (item.status.kind == "scheduled")
                  return `scheduled to start in ${formatDistance(item.status.scheduledExecutionTime, new Date())}`;

                if (item.status.kind == "queued") return `queued - will start soon`;

                return item.status.kind;
              })}
            </Table.Cell>
            <Table.Cell width={"140px"}>
              <Button
                disabled={item.status.kind != "scheduled"}
                onClick={() => startOrQueue({ updateId: item._id }).catch(toast.error)}
              >
                Start or Queue
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};
