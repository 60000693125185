import * as React from "react";
import { Box, Flex, ScrollArea } from "@radix-ui/themes";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { api } from "@repo/convex/convex/_generated/api";
import { MessageAndAvatarRow } from "./MessageAndAvatarRow.tsx";
import { useQuery } from "convex-helpers/react/cache/hooks";

interface Props {
  chatId: Id<"itemChats">;
}

export const ChatMessagesList: React.FC<Props> = ({ chatId }) => {
  const messages = useQuery(api.itemChatMessages.listMyMessagesForChat, { chatId });
  if (!messages) return null;
  return (
    <ScrollArea type={"always"} scrollbars={"vertical"} style={{ height: "calc(100dvh - 300px)" }}>
      <Flex direction={"column"} p="2" pr={"3"} gap={"2"}>
        {messages.map((message) => (
          <MessageAndAvatarRow key={message._id} message={message} />
        ))}
      </Flex>
    </ScrollArea>
  );
};
