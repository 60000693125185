import * as React from "react";
import { DropdownMenu, IconButton } from "@radix-ui/themes";
import { Pencil2Icon, ReaderIcon } from "@radix-ui/react-icons";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { useCommonDialogs } from "../../CommonDialogsProvider.tsx";

interface Props {
  itemId: Id<"items">;
}

export const ItemDetailsMenuItem: React.FC<Props> = ({ itemId }) => {
  const dialogs = useCommonDialogs();
  return (
    <>
      <DropdownMenu.Item onClick={() => dialogs?.openItemDetails({ itemId })}>
        <ReaderIcon />
        Details
      </DropdownMenu.Item>
    </>
  );
};
