import * as React from "react";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { Dialog, Flex } from "@radix-ui/themes";
import { ItemContentSection } from "./ItemContentSection.tsx";
import { ItemDebugSection } from "./ItemDebugSection.tsx";

interface Props {
  itemId: Id<"items"> | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => unknown;
}

export const ItemDetailsDialog: React.FC<Props> = ({ isOpen, itemId, setIsOpen }) => {
  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!itemId) return;
        setIsOpen(isOpen);
      }}
    >
      <Dialog.Content>
        <Dialog.Title mb={"0"}>Item Details</Dialog.Title>
        <Dialog.Description color={"gray"} size={"2"} mb={"3"}>
          Some general information about the item
        </Dialog.Description>

        {itemId && (
          <Flex direction={"column"} gap={"3"}>
            <ItemContentSection itemId={itemId} />
            <ItemDebugSection itemId={itemId} />
          </Flex>
        )}

        <Flex></Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
