import {
  ArchiveIcon,
  CubeIcon,
  GearIcon,
  HamburgerMenuIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import * as React from "react";
import { Routes, routes } from "../../routing/router.ts";
import { Signal } from "@repo/shared/utils/signals/Signal.ts";
import { CommonDialogsContextValue } from "../CommonDialogsProvider.tsx";

export type NavItemData = {
  icon: React.ReactNode;
  label: string;
  onClick?: (options: { commonDialogs: CommonDialogsContextValue | null }) => void;
  getIsSelected: (options: { route: Routes }) => boolean;
  keyboardShortcutHint?: string;
};

const commonNavItems: NavItemData[] = [
  {
    onClick: () => {
      routes.stash().push();
      scrollToTopSignal.dispatch();
    },
    getIsSelected: ({ route }) => route.name === "stash",
    icon: <CubeIcon />,
    label: "Stash",
  },
  {
    getIsSelected: () => false,
    icon: <MagnifyingGlassIcon />,
    label: "Search",
    onClick: ({ commonDialogs }) => commonDialogs?.openSearchDialog(),
    keyboardShortcutHint: "Ctrl+P",
  },
  {
    onClick: () => {
      routes.archive().push();
      scrollToTopSignal.dispatch();
    },
    getIsSelected: ({ route }) => route.name === "archive",
    icon: <ArchiveIcon />,
    label: "Archive",
  },
];

const trashItem: NavItemData = {
  onClick: () => {
    routes.trash().push();
    scrollToTopSignal.dispatch();
  },
  getIsSelected: ({ route }) => route.name === "trash",
  icon: <TrashIcon />,
  label: "Trash",
};

const settingsItem: NavItemData = {
  getIsSelected: ({ route }) => route.name === "settings",
  onClick: () => routes.settings().push(),
  icon: <GearIcon />,
  label: "Settings",
};

export const sidebarNavItems: NavItemData[] = [...commonNavItems, trashItem];

export const bottomNavItems: NavItemData[] = [
  ...commonNavItems,
  {
    getIsSelected: ({}) => false,
    onClick: ({ commonDialogs }) => commonDialogs?.openMoreMenu(),
    icon: <HamburgerMenuIcon />,
    label: "More",
  },
];

export const moreMenuItems: NavItemData[] = [trashItem, settingsItem];

export const scrollToTopSignal = new Signal();
