import * as React from "react";
import { Box, Flex, FlexProps, Text } from "@radix-ui/themes";

interface Props {
  children?: string;
  titleRight?: React.ReactNode;
}

export const SectionTitle: React.FC<Props> = ({ children, titleRight }) => {
  return (
    <Flex
      style={{ backgroundColor: "var(--brown-a3)", borderRadius: "4px" }}
      pl={"2"}
      pt={"1"}
      pb={"1"}
      pr={"2"}
      align={"center"}
    >
      <Text style={{ color: "var(--brown-a11)" }} weight={"bold"}>
        {children}
      </Text>
      <Box flexGrow={"1"} />
      {titleRight}
    </Flex>
  );
};
