import "./common/polyfils.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App.tsx";
import "./index.css";
import { Box, Portal } from "@radix-ui/themes";
import { VisualViewportBox } from "./common/VisualViewportBox.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
