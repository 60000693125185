import * as React from "react";
import { Section } from "../common/section/Section.tsx";
import { Button, Flex } from "@radix-ui/themes";
import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { toast } from "sonner";
import { useAuthActions } from "@convex-dev/auth/react";

interface Props {}

export const DebugSection: React.FC<Props> = ({}) => {
  const importInnoreader = useMutation(api.debug.importFixture);
  const claimAllItems = useMutation(api.debug.claimAllItems);
  const rescrapeAllItems = useMutation(api.debug.rescrapeAllItems);
  const rescrapeAllItemsContents = useMutation(api.debug.rescrapeAllItemContents);
  const updateProfileFromArchive = useMutation(api.debug.updateProfileFromArchive);
  // const cacheHeaderImages = useMutation(api.items.cacheHeaderImagesInStorage);
  const { signOut } = useAuthActions();

  return (
    <Section title={"Debug Actions"}>
      <Flex direction={"row"} gap={"2"} wrap={"wrap"}>
        <Button color={"red"} size={"3"} onClick={() => signOut()}>
          Sign Out
        </Button>
        <Button
          size={"3"}
          onClick={() =>
            importInnoreader()
              .then(() => toast.success(`imported!`))
              .catch(toast.error)
          }
        >
          Import Innoreader Fixture
        </Button>
        <Button
          size={"3"}
          onClick={() =>
            claimAllItems()
              .then(() => toast.success(`claimed!`))
              .catch(toast.error)
          }
        >
          Claim All Items
        </Button>
        <Button
          size={"3"}
          onClick={() =>
            rescrapeAllItems()
              .then(() => toast.success(`rescraping started..`))
              .catch(toast.error)
          }
        >
          Rescrape All
        </Button>
        <Button
          size={"3"}
          onClick={() =>
            rescrapeAllItemsContents()
              .then(() => toast.success(`rescraping started..`))
              .catch(toast.error)
          }
        >
          Rescrape All Contents
        </Button>
        <Button
          size={"3"}
          onClick={() =>
            updateProfileFromArchive()
              .then(() => toast.success(`started..`))
              .catch(toast.error)
          }
        >
          Update Profile From Archive
        </Button>
      </Flex>
    </Section>
  );
};
