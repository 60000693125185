import * as React from "react";
import { useKeyPressEvent } from "react-use";
import { KeyFilter } from "react-use/lib/useKey";

interface Props {
  onPress: () => unknown;
  keyboardKey: string | KeyFilter;
}

export const OnKeyPress: React.FC<React.PropsWithChildren<Props>> = ({ keyboardKey, onPress }) => {
  useKeyPressEvent(keyboardKey, onPress);
  return null;
};
