import * as React from "react";
import { api } from "@repo/convex/convex/_generated/api";
import { useState } from "react";
import { Box, Button, Flex, Heading, Portal } from "@radix-ui/themes";
import { PlusIcon } from "@radix-ui/react-icons";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { AddItemDialog } from "../common/AddItemDialog.tsx";
import { PageTopNav } from "../common/PageTopNav.tsx";
import { ItemsScrollArea } from "../common/items/ItemsScrollArea.tsx";
import { StashItem } from "./StashItem.tsx";
import { usePaginatedItems } from "../common/hooks.ts";
import { UnreadItemDoc } from "@repo/convex/convex/schema.ts";
import { ListedItem } from "@repo/convex/convex/items.ts";
import { Drawer } from "vaul";

interface Props {}

function MyComponent() {
  const [isOpen, setIsOpen] = useState(true);

  React.useEffect(() => {
    const root = document.getElementById("root");
    if (!root) return;

    root.style.transition = "transform 0.3s ease-out";
    root.style.transform = isOpen ? "scale(0.94)" : "scale(1)";

    return () => {
      root.style.transform = "";
      root.style.transition = "";
    };
  }, [isOpen]);

  return (
    <Drawer.Root open={isOpen}>
      <Button onClick={() => setIsOpen(!isOpen)}>Open Drawer</Button>
      <Drawer.Portal>
        <Drawer.Overlay onClick={() => setIsOpen(false)} className="fixed inset-0 bg-black/40" />
        <Drawer.Content className="bg-gray-100 h-fit fixed bottom-0 left-0 right-0 outline-none">
          <div className="p-4 bg-white min-h-[300px]">this is content</div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export const StashPage: React.FC<Props> = ({}) => {
  const { status, loadMore, isLoading, items } = usePaginatedItems({
    statusKind: "unread",
  });
  const count = useQuery(api.items.count, { statusKind: "unread" });
  const [isAddOpen, setIsAddOpen] = useState(false);

  return (
    <Flex height={"100%"} direction={"column"}>
      {/* <MyComponent /> */}
      {isAddOpen && <AddItemDialog setIsOpen={setIsAddOpen} />}
      <PageTopNav>
        <Flex align={"stretch"}>
          <Box flexGrow={"1"}>
            <Heading as={"h1"}>Stashed ({count == 500 ? "500+" : count})</Heading>
          </Box>
          <Button variant={"soft"} size={"2"} onClick={() => setIsAddOpen(true)}>
            <PlusIcon />
            Add Item
          </Button>
        </Flex>
      </PageTopNav>
      <ItemsScrollArea
        items={items}
        isLoading={isLoading}
        onAddItem={() => setIsAddOpen(true)}
        renderItem={(item) => <StashItem key={item._id} item={item as ListedItem<UnreadItemDoc>} />}
        onLoadMore={() => loadMore(20)}
        canLoadMore={status == "CanLoadMore"}
      />
    </Flex>
  );
};
