import * as React from "react";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { NotesEditorDialog } from "../notes/editor/NotesEditorDialog.tsx";
import { useContext, useMemo, useState } from "react";
import { ItemDetailsDialog } from "../itemDetails/ItemDetailsDialog.tsx";
import { SearchDialog } from "../search/SearchDialog.tsx";
import { MoreMenu } from "./nav/moreMenu/MoreMenu.tsx";
import { OnKeyPress } from "./OnKeyPress.tsx";
import { ArchiveItemDialog } from "../stash/dialog/ArchiveItemDialog.tsx";
import { ItemChatDialog } from "../chat/itemChatDialog/ItemChatDialog.tsx";

interface Props {
  children?: React.ReactNode;
}

type CommonItemDialogProps = { itemId: Id<"items"> };
type CommonItemDialogState = CommonItemDialogProps | null;
type SearchDialogState = {} | null;
type MoreMenuState = {} | null;

export const CommonDialogsProvider: React.FC<Props> = ({ children }) => {
  const [itemNotesEditorState, setItemNotesEditorState] = useState<CommonItemDialogState>(null);
  const [postArchiveState, setPostArchiveState] = useState<CommonItemDialogState>(null);
  const [itemDetailsState, setItemDetailsState] = useState<CommonItemDialogState>(null);
  const [searchDialogState, setSearchDialogState] = useState<SearchDialogState>(null);
  const [moreMenuState, setMoreMenuState] = useState<MoreMenuState>(null);
  const [chatDialogState, setChatDialogState] = useState<CommonItemDialogState>(null);

  const value: CommonDialogsContextValue = useMemo(
    () => ({
      openItemNotesEditor: (props) => setItemNotesEditorState(props),
      openPostArchive: (props) => setPostArchiveState(props),
      openItemDetails: (props) => setItemDetailsState(props),
      openSearchDialog: () => setSearchDialogState({}),
      openMoreMenu: () => setMoreMenuState({}),
      openItemChat: (props) => setChatDialogState(props),
    }),
    [setItemNotesEditorState, setPostArchiveState, setItemDetailsState],
  );

  return (
    <CommonDialogsContext.Provider value={value}>
      <OnKeyPress
        keyboardKey={(e) => {
          if (e.key == "p" && e.ctrlKey) {
            e.preventDefault();
            return true;
          }
          return false;
        }}
        onPress={() => setSearchDialogState((s) => !s)}
      />
      <NotesEditorDialog
        isOpen={itemNotesEditorState?.itemId != null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setItemNotesEditorState(null);
        }}
        itemId={itemNotesEditorState?.itemId ?? null}
      />
      <ArchiveItemDialog
        isOpen={postArchiveState?.itemId != null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setPostArchiveState(null);
        }}
        itemId={postArchiveState?.itemId ?? null}
      />
      <ItemDetailsDialog
        isOpen={itemDetailsState?.itemId != null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setItemDetailsState(null);
        }}
        itemId={itemDetailsState?.itemId ?? null}
      />
      <SearchDialog
        isOpen={searchDialogState != null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setSearchDialogState(null);
        }}
      />
      <ItemChatDialog
        isOpen={chatDialogState != null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setChatDialogState(null);
        }}
        itemId={chatDialogState?.itemId ?? null}
      />
      <MoreMenu
        isOpen={moreMenuState != null}
        setIsOpen={(isOpen) => {
          if (!isOpen) setMoreMenuState(null);
        }}
      />
      {children}
    </CommonDialogsContext.Provider>
  );
};

export interface CommonDialogsContextValue {
  openItemNotesEditor: (props: CommonItemDialogProps) => unknown;
  openPostArchive: (props: CommonItemDialogProps) => unknown;
  openItemDetails: (props: CommonItemDialogProps) => unknown;
  openSearchDialog: () => unknown;
  openMoreMenu: () => unknown;
  openItemChat: (props: CommonItemDialogProps) => unknown;
}

export const CommonDialogsContext = React.createContext<CommonDialogsContextValue | null>(null);

export const useCommonDialogs = () => useContext(CommonDialogsContext);
