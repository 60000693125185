import * as React from "react";
import { Box, Flex } from "@radix-ui/themes";
import { bottomNavItems } from "../nav.tsx";
import { BottomNavItem } from "./BottomNavItem.tsx";

interface Props {}

export const BottomNav: React.FC<Props> = ({}) => {
  return (
    <Flex
      align={"center"}
      justify={"center"}
      position={"fixed"}
      bottom={"0px"}
      left={"0px"}
      width={"100dvw"}
    >
      <Flex
        direction={"row"}
        gap={"3"}
        p={"2"}
        pb={"calc(var(--safe-area-inset-bottom, 5px) + 5px)"}
      >
        {bottomNavItems.map((item, i) => (
          <BottomNavItem key={i} item={item}>
            {item.label}
          </BottomNavItem>
        ))}
      </Flex>
    </Flex>
  );
};
