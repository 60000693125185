import * as React from "react";
import { Box, Flex, Text } from "@radix-ui/themes";
import { LogoIcon } from "./LogoIcon.tsx";
import { AppTitle } from "@repo/ui/AppTitle.tsx";

interface Props {
  children?: React.ReactNode;
}

export const LogoAndTitle: React.FC<Props> = ({ children }) => {
  return (
    <Flex direction={"column"} align={"center"} gap={"1"}>
      <LogoIcon />
      <AppTitle />
    </Flex>
  );
};
