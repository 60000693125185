"use client";
import * as React from "react";

interface Props {
  children?: React.ReactNode;
}

export const OnlyInCapacitor: React.FC<Props> = ({ children }) => {
  const [canRender, setCanRender] = React.useState(false);

  React.useEffect(() => {
    setCanRender((globalThis as any).Capacitor != undefined);
  }, []);

  if (!canRender) return;
  return <>{children}</>;
};
