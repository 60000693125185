import * as React from "react";
import { Theme } from "@radix-ui/themes";

interface Props {
  children?: React.ReactNode;
}

export const StashItTheme: React.FC<Props> = ({ children }) => {
  return (
    <Theme accentColor="brown" grayColor="sand" panelBackground="translucent" scaling="95%">
      {children}
    </Theme>
  );
};
