import * as React from "react";
import { Box, Button, Card, Flex, Inset, Text } from "@radix-ui/themes";
import { Doc } from "@repo/convex/convex/_generated/dataModel";
import { style } from "typestyle";

interface Props {
  actions: React.ReactNode;
  item: Doc<"items">;
  menu?: React.ReactNode;
  children?: React.ReactNode;
}

export const defaultHeaderImageUrl = `https://images.unsplash.com/photo-1617050318658-a9a3175e34cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&q=80`;

export const ItemCard: React.FC<Props> = ({ actions, item, menu, children }) => {
  return (
    <Box width={{ initial: "calc(100dvw - 55px)", xs: "350px" }}>
      <Card
        size="2"
        asChild
        style={{ padding: 0 }}
        className={style({
          transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
          $nest: {
            "&:hover": {
              transform: "translateY(-4px)",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            },
          },
        })}
      >
        <Box>
          <Box position={"relative"} pb={"2"}>
            <Inset clip="padding-box" side="top" pb="0">
              <a href={item.url} target={"_blank"}>
                <img
                  src={item.headerImageUrl || defaultHeaderImageUrl}
                  alt="Item img"
                  style={{
                    display: "block",
                    objectFit: "cover",
                    width: "100%",
                    height: 180,
                    backgroundColor: "var(--gray-5)",
                  }}
                />
              </a>
            </Inset>
            <Box position={"absolute"} top={"10px"} right={"0"}></Box>
          </Box>

          <Flex direction={"column"}>
            <Flex direction={"column"} pl={"3"} pr={"3"} pb={"3"}>
              <a href={item.url} target={"_blank"}>
                <Text
                  as="p"
                  size="4"
                  weight={"bold"}
                  style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.title}
                </Text>
              </a>

              <a href={item.url} target={"_blank"}>
                <Text
                  as="p"
                  size="1"
                  color={"gray"}
                  style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.url}
                </Text>
              </a>

              {children}
            </Flex>

            <Box style={{ backgroundColor: "var(--brown-a2)" }}>
              <Flex direction={"row"} align={"baseline"} p={"2"}>
                {actions}
                <Box flexGrow={"1"} />
                {menu ? <Box pr={"1"}>{menu}</Box> : null}
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Card>
    </Box>
  );
};
