import * as React from "react";
import { Box } from "@radix-ui/themes";

interface Props {
  children?: React.ReactNode;
}

export const PageTopNav: React.FC<Props> = ({ children }) => {
  return (
    <Box
      width={"100%"}
      style={{
        background: "var(--brown-a3)",
        color: "var(--brown-a11)",
      }}
      pl={"6"}
      pr={"4"}
      pt={"4"}
      pb={"3"}
    >
      {children}
    </Box>
  );
};
