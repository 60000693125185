import * as React from "react";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { Box, Button, CheckboxCards, Dialog, Flex, Switch, Text, Tooltip } from "@radix-ui/themes";
import { ArrowLeftIcon, QuestionMarkCircledIcon, QuestionMarkIcon } from "@radix-ui/react-icons";
import { api } from "@repo/convex/convex/_generated/api";
import { toast } from "sonner";
import { optimisticallyUpdateMySettings } from "../../settings/settingsHooks.ts";
import { useQuery } from "convex-helpers/react/cache/hooks";

interface Props {
  itemId: Id<"items"> | null;
  onClose: () => unknown;
  onPrevious: () => unknown;
}

export const Part2UpdateProfile: React.FC<Props> = ({ itemId, onClose, onPrevious }) => {
  const settings = useQuery(api.userSettings.getMineOrDefault);
  const updateSettings = optimisticallyUpdateMySettings();

  return (
    <>
      <Dialog.Title mb={"0"}>Others</Dialog.Title>
      <Dialog.Description color={"gray"} size={"2"} mb={"3"}>
        Other things we can do
      </Dialog.Description>

      <Flex
        style={{
          border: "1px solid var(--brown-a4)",
          borderRadius: "var(--radius-3)",
          cursor: "pointer",
        }}
        gap="2"
        p={"4"}
        maxWidth={"400px"}
        onClick={() => {
          updateSettings({
            shouldUpdateMyProfileWhenArchivingItem:
              !settings?.settings.shouldUpdateMyProfileWhenArchivingItem,
          }).catch(toast.error);
        }}
      >
        <Flex direction={"column"} flexGrow={"1"}>
          <Text>Update My Profile</Text>
          <Text size={"1"} color={"gray"}>
            Uses the item content and your notes to iteratively update your profile.
          </Text>
          <Text size={"1"} color={"gray"}>
            This is used for other other AI enhanced features in StashIt.
          </Text>
          <Text size={"1"} color={"gray"}>
            View and edit your profile at any time in the settings.
          </Text>
        </Flex>

        <Switch size="2" checked={settings?.settings.shouldUpdateMyProfileWhenArchivingItem} />
      </Flex>

      <Flex gap="3" mt="4" justify="end">
        <Button size={"3"} onClick={onPrevious}>
          <ArrowLeftIcon /> Back
        </Button>
        <Box flexGrow={"1"} />
        <Button size={"3"} onClick={onClose}>
          Archive
        </Button>
      </Flex>
    </>
  );
};
