import { v4 as uuidv4 } from "uuid";

if (!globalThis.crypto) {
  globalThis.crypto = {} as any;
  console.log("polyfilled crypto");
}

if (!globalThis.crypto.randomUUID) {
  globalThis.crypto.randomUUID = uuidv4 as any;
  console.log("polyfilled crypto.randomUUID");
}
