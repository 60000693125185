import * as React from "react";
import { Avatar, AvatarProps } from "@radix-ui/themes";

type Props = {
  children?: React.ReactNode;
} & Omit<AvatarProps, "fallback" | "src">;

export const MeAvatar: React.FC<Props> = ({ children, ...rest }) => {
  return <Avatar size="2" src={""} fallback="A" {...rest} />; // me?.imageUrl
};
