import * as React from "react";
import { DropdownMenu } from "@radix-ui/themes";
import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { ConfirmDeleteDialog } from "../stash/ConfirmDeleteDialog.tsx";
import { toast } from "sonner";
import { FileMinusIcon, TrashIcon } from "@radix-ui/react-icons";
import { Doc } from "@repo/convex/convex/_generated/dataModel";

interface Props {
  item: Doc<"items">;
}

export const PermanentlyDeleteMenuItem: React.FC<Props> = ({ item }) => {
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const trash = useMutation(api.items.permanentlyDelete);
  return (
    <>
      <DropdownMenu.Item
        color="red"
        onClick={(e) => {
          setIsDeleteOpen(true);
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      >
        <FileMinusIcon />
        Delete
      </DropdownMenu.Item>
      {isDeleteOpen && (
        <ConfirmDeleteDialog
          onOpenChange={setIsDeleteOpen}
          onConfirm={() => {
            setIsDeleteOpen(false);
            trash({ itemId: item._id })
              .then(() => toast.error("Item deleted"))
              .catch(console.error);
          }}
        />
      )}
    </>
  );
};
