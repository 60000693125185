import * as React from "react";
import { Avatar, Box, Flex } from "@radix-ui/themes";
import { Doc } from "@repo/convex/convex/_generated/dataModel";
import "./MessageAndAvatarRow.css";
import { ChatMessageContent } from "./ChatMessageContent.tsx";

interface Props {
  message: Doc<"itemChatMessages">;
}

export const MessageAndAvatarRow: React.FC<Props> = ({ message }) => {
  if (message.speaker == "assistant")
    return (
      <Flex gap={"2"}>
        <Avatar fallback={"🤖"} />
        <Box
          key={message._id}
          p={"3"}
          style={{
            backgroundColor: "var(--brown-3)",
            position: "relative",
            borderRadius: "var(--radius-4)",
            maxWidth: "400px",
          }}
        >
          <ChatMessageContent message={message} />
          <Box
            style={{
              position: "absolute",
              left: "0",
              top: "20px",
              width: "0",
              height: "0",
              border: "5px solid transparent",
              borderRightColor: "var(--brown-3)",
              borderLeft: "0",
              marginTop: "-5px",
              marginLeft: "-5px",
            }}
          />
        </Box>
      </Flex>
    );

  return (
    <Flex direction={"row"} justify={"end"} gap={"2"}>
      <Box
        key={message._id}
        p={"3"}
        style={{
          backgroundColor: "var(--blue-3)",
          position: "relative",
          borderRadius: "var(--radius-4)",
          maxWidth: "400px",
        }}
      >
        <ChatMessageContent message={message} />
        <Box
          style={{
            position: "absolute",
            right: "0",
            top: "20px",
            width: "0",
            height: "0",
            border: "5px solid transparent",
            borderLeftColor: "var(--blue-3)",
            borderRight: "0",
            marginTop: "-5px",
            marginRight: "-5px",
          }}
        />
      </Box>
      <Avatar fallback={"A"} color={"blue"} />
    </Flex>
  );
};
