import * as React from "react";
import { Flex } from "@radix-ui/themes";

interface Props {
  children?: React.ReactNode;
}

export const ItemActions: React.FC<Props> = ({ children }) => {
  return (
    <Flex direction={"row"} gap={"2"}>
      {children}
    </Flex>
  );
};
