import { createRouter, defineRoute, param, Route } from "type-route";

export const { RouteProvider, useRoute, routes } = createRouter({
  root: defineRoute("/"),
  stash: defineRoute("/stash"),
  archive: defineRoute("/archive"),
  settings: defineRoute("/settings"),
  trash: defineRoute("/trash"),
});

export type Routes = Route<typeof routes>;
