import * as React from "react";
import { IconButton } from "@radix-ui/themes";
import { ChatBubbleIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { useCommonDialogs } from "../CommonDialogsProvider.tsx";

interface Props {
  itemId: Id<"items">;
}

export const ItemChatActionButton: React.FC<Props> = ({ itemId }) => {
  const dialogs = useCommonDialogs();
  return (
    <>
      <IconButton variant={"soft"} onClick={() => dialogs?.openItemChat({ itemId })}>
        <ChatBubbleIcon />
      </IconButton>
    </>
  );
};
