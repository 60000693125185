import * as React from "react";
import { match } from "ts-pattern";
import { Spinner, Text } from "@radix-ui/themes";
import { formatDistance } from "date-fns";
import { SaveState } from "./ConnectedNotesEditor.tsx";

interface Props {
  state: SaveState;
}

export const SaveStateLabel: React.FC<Props> = ({ state }) => {
  return (
    <>
      {match(state)
        .with({ kind: "saving" }, () => <Spinner size={"1"} />)
        .with({ kind: "loading" }, () => <Spinner size={"1"} />)
        .with({ kind: "loaded" }, () => null)
        .with({ kind: "saved" }, ({ at }) => (
          <Text size={"1"} color={"gray"}>
            Saved {formatDistance(at, new Date())} ago
          </Text>
        ))
        .exhaustive()}
    </>
  );
};
