"use client";
import * as React from "react";
import { useEffect } from "react";
import { SendIntent } from "send-intent";
import { toast } from "sonner";
import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";

interface Props {}

export const SendIntentListener: React.FC<Props> = ({}) => {
  const add = useMutation(api.items.add);

  useEffect(() => {
    const onSendIntentReceived = () => {
      SendIntent.checkSendIntentReceived()
        .then(async (result) => {
          console.log("SendIntent received from inside react!!", result);

          if (!result.url) return;
          const url = decodeURIComponent(result.url);
          toast.info(`Stashing '${url}'..`);
          await add({ url });
          toast.success(`'${url}' stashed for later!`);
        })
        .catch(toast.error);
    };

    window.addEventListener("sendIntentReceived", onSendIntentReceived);

    return () => {
      window.removeEventListener("sendIntentReceived", onSendIntentReceived);
    };
  }, []);

  return null;
};
