import * as React from "react";
import { Button } from "@radix-ui/themes";
import { SignInDialog } from "./SignInDialog.tsx";

interface Props {
  children?: React.ReactNode;
}

export const SignInButton: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Button size={"3"} onClick={() => setIsOpen(true)}>
        Login
      </Button>
      {isOpen && <SignInDialog onOpenChange={setIsOpen} />}
    </>
  );
};
