import * as React from "react";
import { Button, Dialog, Flex, Text } from "@radix-ui/themes";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { ConnectedNotesEditor } from "../../notes/editor/ConnectedNotesEditor.tsx";

interface Props {
  itemId: Id<"items"> | null;
  onNext: () => unknown;
}

export const Part1Notes: React.FC<Props> = ({ itemId, onNext }) => {
  return (
    <>
      <Dialog.Title mb={"0"}>Item Notes</Dialog.Title>
      <Dialog.Description color={"gray"} size={"2"} mb={"3"}>
        Be specific and personal, this is searchable and AI will help you find it later.
      </Dialog.Description>

      <ConnectedNotesEditor itemId={itemId} />

      <Flex gap="3" mt="4" justify="end">
        <Button size={"3"} onClick={onNext}>
          Next <ArrowRightIcon />
        </Button>
      </Flex>
    </>
  );
};
