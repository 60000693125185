import * as React from "react";
import { DropdownMenu } from "@radix-ui/themes";
import { toast } from "sonner";
import { TrashIcon } from "@radix-ui/react-icons";
import { Doc } from "@repo/convex/convex/_generated/dataModel";
import { useOptimisticTrashItem } from "../../hooks.ts";

interface Props {
  item: Doc<"items">;
}

export const TrashItemMenuItem: React.FC<Props> = ({ item }) => {
  const trash = useOptimisticTrashItem();
  return (
    <>
      <DropdownMenu.Item color="red" onClick={() => trash({ itemId: item._id }).catch(toast.error)}>
        <TrashIcon />
        Trash
      </DropdownMenu.Item>
    </>
  );
};
