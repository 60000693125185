import * as React from "react";
import { Doc } from "@repo/convex/convex/_generated/dataModel";
import { Button, Flex, IconButton } from "@radix-ui/themes";
import { ArchiveIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { ItemCard } from "../common/items/ItemCard.tsx";
import { UnreadItemDoc } from "@repo/convex/convex/schema.ts";
import { ItemMenu } from "../common/items/ItemMenu.tsx";
import { TrashItemMenuItem } from "../common/items/menu/TrashItemMenuItem.tsx";
import { CommonMenuItems } from "../common/items/menu/CommonMenuItems.tsx";
import { ListedItem } from "@repo/convex/convex/items.ts";
import { ItemActions } from "../common/items/ItemActions.tsx";
import { ItemNotesActionButton } from "../common/items/ItemNotesActionButton.tsx";
import { useCommonDialogs } from "../common/CommonDialogsProvider.tsx";
import { ItemChatActionButton } from "../common/items/ItemChatActionButton.tsx";

interface Props {
  item: ListedItem<UnreadItemDoc>;
}

export const StashItem: React.FC<Props> = ({ item }) => {
  const dialogs = useCommonDialogs();

  return (
    <ItemCard
      item={item}
      actions={
        <ItemActions>
          <Button
            variant={"surface"}
            onClick={(e) => {
              dialogs?.openPostArchive({ itemId: item._id });
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ArchiveIcon /> Archive
          </Button>
          {item.hasNotes ? <ItemNotesActionButton itemId={item._id} /> : null}
          {item.hasChat ? <ItemChatActionButton itemId={item._id} /> : null}
        </ItemActions>
      }
      menu={
        <ItemMenu>
          <CommonMenuItems item={item} />
          <TrashItemMenuItem item={item} />
        </ItemMenu>
      }
    />
  );
};
