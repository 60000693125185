import * as React from "react";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { Box, Dialog, Flex, Portal, TextField } from "@radix-ui/themes";
import { ConnectedNotesEditor, SaveState } from "./ConnectedNotesEditor.tsx";
import { SaveStateLabel } from "./SaveStateLabel.tsx";
import { useVisualViewportSize, VisualViewportBox } from "../../common/VisualViewportBox.tsx";

interface Props {
  itemId: Id<"items"> | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => unknown;
}

export const NotesEditorDialog: React.FC<Props> = ({ itemId, setIsOpen, isOpen }) => {
  const [saveState, setSaveState] = React.useState<SaveState>({ kind: "loading" });
  // const viewportSize = useVisualViewportSize();
  // if (!isOpen) return;

  // return (
  //   <Portal>
  //     <VisualViewportBox
  //       style={{
  //         backgroundColor: "rgba(0,255,0,0.2)",
  //         borderRadius: "100px",
  //         zIndex: 1000,
  //         pointerEvents: "initial",
  //         top: 0,
  //         left: 0,
  //       }}
  //     >
  //       <Box
  //         style={{
  //           position: "absolute",
  //           bottom: "0",
  //           left: "50%",
  //           transform: "translate(-50%, 0%)",
  //           width: "300px",
  //           backgroundColor: "white",
  //           height: "100%",
  //           padding: "10px",
  //           pointerEvents: "initial",
  //         }}
  //       >
  //         <textarea style={{ width: "100%", height: "100%" }} />
  //       </Box>
  //     </VisualViewportBox>
  //   </Portal>
  //);

  // return (
  //   <Portal>
  //     <Box
  //       style={{
  //         position: "absolute",
  //         top: 0,
  //         left: 0,
  //         width: "100%",
  //         height: "100%",
  //         backgroundColor: "rgba(0,0,0,0.2)",
  //       }}
  //     >
  //       <Box
  //         style={{
  //           position: "fixed",
  //           bottom: "0",
  //           left: "50%",
  //           transform: "translate(-50%, 0%)",
  //           width: "300px",
  //           backgroundColor: "white",
  //           height: viewportSize.height,
  //           padding: "10px",
  //         }}
  //       >
  //         <textarea style={{ width: "100%", height: "100%" }} />
  //       </Box>
  //     </Box>
  //   </Portal>
  // );

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!itemId) return;
        setIsOpen(isOpen);
      }}
    >
      <Dialog.Content
        align={"start"}
        style={{
          marginTop: "calc(var(--safe-area-inset-top, 20px) + 30px)",
        }}
      >
        <Dialog.Title mb={"0"}>Item Notes</Dialog.Title>
        <Dialog.Description color={"gray"} size={"2"} mb={"3"}>
          Add some AI searchable notes
        </Dialog.Description>

        <Flex direction="column" gap="3">
          {isOpen ? (
            <ConnectedNotesEditor itemId={itemId} onSaveStateChange={setSaveState} />
          ) : null}
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <SaveStateLabel state={saveState} />
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
