import * as React from "react";
import { Button, Dialog, Flex, Text, TextField } from "@radix-ui/themes";
import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";

interface Props {
  setIsOpen: (isOpen: boolean) => unknown;
}

export const AddItemDialog: React.FC<Props> = ({ setIsOpen }) => {
  const [url, setUrl] = React.useState<string>("" as string);
  const addItem = useMutation(api.items.add);

  return (
    <Dialog.Root open={true} onOpenChange={setIsOpen}>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Stash Item</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Add an item to your stash
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Url
            </Text>
            <TextField.Root
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="e.g. https://mikecann.co.uk"
            />
          </label>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button onClick={() => addItem({ url }).catch(console.error)}>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
