import * as React from "react";
import { Button, Dialog, Flex, Text, TextField } from "@radix-ui/themes";
import { useAuthActions } from "@convex-dev/auth/react";
import { useState } from "react";
import { toast } from "sonner";

interface Props {
  onOpenChange?: (open: boolean) => void;
}

export const SignInDialog: React.FC<Props> = ({ onOpenChange }) => {
  const authActions = useAuthActions();
  const [step, setStep] = useState<"signUp" | "signIn">("signIn");
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Dialog.Root open={true} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
        <Dialog.Title>{step == "signIn" ? `Sign in` : `Sign up`}</Dialog.Title>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setIsSubmitting(true);
            const formData = new FormData(event.currentTarget);
            authActions
              .signIn("password", formData)
              .then(() => {
                console.log("Signed in");
                window.location.reload();
              })
              .catch((error) => {
                console.error(error);
                toast.error(
                  step === "signIn"
                    ? "Could not sign in, did you mean to sign up?"
                    : "Could not sign up, did you mean to sign in?",
                );
              })
              .finally(() => setIsSubmitting(false));
          }}
        >
          <Flex direction="column" gap="3">
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Email
              </Text>
              <TextField.Root name="email" type={"email"} placeholder="Enter your email" />
            </label>
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Password
              </Text>
              <TextField.Root name="password" type={"password"} />
            </label>
            <input name="flow" type="hidden" value={step} />
          </Flex>

          <Flex direction={"column"} gap="3" mt="4" justify="end">
            <Button loading={isSubmitting} disabled={isSubmitting} type="submit">
              {step === "signIn" ? "Sign in" : "Sign up"}
            </Button>
            {isSubmitting ? null : (
              <Button
                variant={"ghost"}
                type={"button"}
                onClick={() => {
                  setStep(step === "signIn" ? "signUp" : "signIn");
                }}
              >
                {step === "signIn" ? "Sign up instead" : "Sign in instead"}
              </Button>
            )}
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
