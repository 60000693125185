import * as React from "react";
import { Box, Flex, Text } from "@radix-ui/themes";
import { useRoute } from "../../../routing/router.ts";
import { NavItemData } from "../nav.tsx";
import { useCommonDialogs } from "../../CommonDialogsProvider.tsx";
import { style } from "typestyle";

interface Props {
  children?: React.ReactNode;
  item: NavItemData;
}

export const SideNavItem: React.FC<Props> = ({ children, item }) => {
  const commonDialogs = useCommonDialogs();
  const route = useRoute();
  const isSelected = item.getIsSelected({ route });
  return (
    <Flex
      direction={"row"}
      align={"center"}
      position={"relative"}
      onClick={() => item.onClick?.({ commonDialogs })}
      p={"2"}
      pl={"4"}
      pb={"1"}
      pt={"3"}
      justify={"start"}
      className={style({
        userSelect: "none",
        borderRadius: "var(--radius-4) 0 0 var(--radius-4)",
        cursor: "pointer",
        color: isSelected ? "var(--brown-11)" : "var(--black-2)",
        fontSize: "1em",
        background: isSelected ? "var(--brown-a4)" : "transparent",
        $nest: {
          "&:hover": {
            background: isSelected ? "var(--brown-a4)" : "var(--brown-a2)",
          },
        },
      })}
    >
      <Box display={"inline-block"} mr={"2"} pb={"1"}>
        {item.icon}
      </Box>
      <Box pb={"1"} flexGrow={"1"}>
        {children}
      </Box>
      {item.keyboardShortcutHint ? (
        <Text
          size={"1"}
          color={"gray"}
          mb={"2"}
          style={{
            border: "1px solid rgba(0,0,0,0.1)",
            padding: "2px 4px",
            borderRadius: "var(--radius-4)",
            opacity: 0.5,
            position: "absolute",
            top: "5px",
            right: "10px",
          }}
        >
          {item.keyboardShortcutHint}
        </Text>
      ) : null}
    </Flex>
  );
};
