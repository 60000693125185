import * as React from "react";
import { api } from "@repo/convex/convex/_generated/api";
import { useState } from "react";
import { Box, Flex, Heading } from "@radix-ui/themes";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { usePaginatedItems } from "../common/hooks.ts";
import { AddItemDialog } from "../common/AddItemDialog.tsx";
import { PageTopNav } from "../common/PageTopNav.tsx";
import { ItemsScrollArea } from "../common/items/ItemsScrollArea.tsx";
import { ArchiveItem } from "./ArchiveItem.tsx";
import { ArchivedItemDoc } from "@repo/convex/convex/schema.ts";
import { ListedItem } from "@repo/convex/convex/items.ts";

interface Props {}

export const ArchivePage: React.FC<Props> = ({}) => {
  const { status, loadMore, isLoading, items } = usePaginatedItems({
    statusKind: "archived",
  });
  const count = useQuery(api.items.count, { statusKind: "archived" });

  return (
    <Flex height={"100%"} direction={"column"}>
      <PageTopNav>
        <Flex align={"stretch"}>
          <Box flexGrow={"1"}>
            <Heading as={"h1"}>Archived ({count == 500 ? "500+" : count})</Heading>
          </Box>
        </Flex>
      </PageTopNav>
      <ItemsScrollArea
        items={items}
        isLoading={isLoading}
        renderItem={(item) => (
          <ArchiveItem key={item._id} item={item as ListedItem<ArchivedItemDoc>} />
        )}
        onLoadMore={() => loadMore(20)}
        canLoadMore={status == "CanLoadMore"}
      />
    </Flex>
  );
};
