import * as React from "react";
import { Box, Button, Card, Flex, Inset, Separator, Strong, Text } from "@radix-ui/themes";
import { ArchiveIcon, CheckIcon, ResetIcon, TrashIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";
import { useOptimisticUnArchiveItem } from "../common/hooks.ts";
import { ItemCard } from "../common/items/ItemCard.tsx";
import { ArchivedItemDoc } from "@repo/convex/convex/schema.ts";
import { ItemMenu } from "../common/items/ItemMenu.tsx";
import { PermanentlyDeleteMenuItem } from "../trash/PermanentlyDeleteMenuItem.tsx";
import { TrashItemMenuItem } from "../common/items/menu/TrashItemMenuItem.tsx";
import { CommonMenuItems } from "../common/items/menu/CommonMenuItems.tsx";
import { ItemActions } from "../common/items/ItemActions.tsx";
import { ItemNotesActionButton } from "../common/items/ItemNotesActionButton.tsx";
import { ListedItem } from "@repo/convex/convex/items.ts";

interface Props {
  item: ListedItem<ArchivedItemDoc>;
}

export const ArchiveItem: React.FC<Props> = ({ item }) => {
  const unarchive = useOptimisticUnArchiveItem();
  return (
    <ItemCard
      item={item}
      actions={
        <ItemActions>
          <Button
            variant={"surface"}
            onClick={(e) => {
              unarchive({ itemId: item._id })
                .then(() => toast.success("Item moved to stash"))
                .catch(console.error);

              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ResetIcon /> Unarchive
          </Button>
          {item.hasNotes ? <ItemNotesActionButton itemId={item._id} /> : null}
        </ItemActions>
      }
      menu={
        <ItemMenu>
          <CommonMenuItems item={item} />
          <TrashItemMenuItem item={item} />
        </ItemMenu>
      }
    />
  );
};
