import * as React from "react";
import { Box, IconButton, TextField } from "@radix-ui/themes";
import { MagnifyingGlassIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { toast } from "sonner";

interface Props {
  chatId: Id<"itemChats">;
}

export const ChatMessageEntryBox: React.FC<Props> = ({ chatId }) => {
  const [message, setMessage] = React.useState("");
  const createMessage = useMutation(api.itemChatMessages.createMessageForUser);

  return (
    <Box
      m={"2"}
      style={{
        backgroundColor: "white",
        borderRadius: "var(--radius-4)",
      }}
    >
      <form
        onSubmit={(e) => {
          console.log("submit");
          createMessage({ message, chatId }).catch(toast.error);
          e.preventDefault();
          setMessage("");
        }}
      >
        <TextField.Root
          variant={"soft"}
          size="3"
          placeholder=""
          style={{ border: "1px solid var(--brown-6)", backgroundColor: "var(--brown-2)" }}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        >
          <TextField.Slot side={"right"} pr={"1"}>
            <IconButton type={"submit"} style={{ borderRadius: "var(--radius-3)" }}>
              <TriangleRightIcon />
            </IconButton>
          </TextField.Slot>
        </TextField.Root>
      </form>
    </Box>
  );
};
