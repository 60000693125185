import * as React from "react";
import { iife } from "@repo/shared/utils/misc.ts";
import { RootPage } from "../root/RootPage.tsx";
import { useRoute } from "./router.ts";
import { MainPageLayout } from "../layout/MainPageLayout.tsx";
import { StashPage } from "../stash/StashPage.tsx";
import { ArchivePage } from "../archive/ArchivePage.tsx";
import { SettingsPage } from "../settings/SettingsPage.tsx";
import { TrashPage } from "../trash/TrashPage.tsx";

interface Props {}

export const Routes: React.FC<Props> = ({}) => {
  const route = useRoute();

  if (route.name == "root") return <RootPage />;

  return (
    <MainPageLayout>
      {iife(() => {
        if (route.name == "stash") return <StashPage />;
        if (route.name == "archive") return <ArchivePage />;
        if (route.name == "settings") return <SettingsPage />;
        if (route.name == "trash") return <TrashPage />;
        return null;
      })}
    </MainPageLayout>
  );
};
