import "@radix-ui/themes/styles.css";
import { Box, Theme } from "@radix-ui/themes";
import { RouteProvider } from "../routing/router.ts";
import { ConvexClientProvider } from "../common/ConvexClientProvider.tsx";
import { OnlyInCapacitor } from "../common/OnlyInCapacitor.tsx";
import { SendIntentListener } from "../sendIntent/SendIntentListener.tsx";
import { SafeAreasActivator } from "../sendIntent/SafeAreasActivator.tsx";
import { CapacitorUpdatesInterface } from "../updates/CapacitorUpdatesInterface.tsx";
import { Toaster } from "sonner";
import { Routes } from "../routing/Routes.tsx";
import { ensure } from "@repo/shared/utils/ensure.ts";
import { CommonDialogsProvider } from "../common/CommonDialogsProvider.tsx";
import { StashItTheme } from "./StashItTheme.tsx";
import { Authenticated } from "convex/react";
import { VisualViewportBox } from "../common/VisualViewportBox.tsx";
import React from "react";



function App() {
  return (
    <RouteProvider>
      <StashItTheme>
        <ConvexClientProvider
          url={ensure(
            import.meta.env.VITE_CONVEX_INTERNAL_URL,
            `missing the process.env.VITE_CONVEX_INTERNAL_URL`,
          )}
        >
          <CommonDialogsProvider>
            <OnlyInCapacitor>
              <Authenticated>
                <SendIntentListener />
              </Authenticated>
              <SafeAreasActivator />
              <CapacitorUpdatesInterface />
            </OnlyInCapacitor>
            <Routes />
            <Toaster
              richColors
              closeButton={true}
              position={"bottom-center"}
              style={{
                marginBottom: "calc(var(--safe-area-inset-bottom, 0px) + 20px)",
              }}
            />
          </CommonDialogsProvider>
        </ConvexClientProvider>
      </StashItTheme>
    </RouteProvider>
  );
}

export default App;
