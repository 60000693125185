import * as React from "react";
import { DropdownMenu } from "@radix-ui/themes";
import { Pencil2Icon } from "@radix-ui/react-icons";
import { Doc, Id } from "@repo/convex/convex/_generated/dataModel";
import { useCommonDialogs } from "../../CommonDialogsProvider.tsx";

interface Props {
  itemId: Id<"items">;
}

export const EditNotesMenuItem: React.FC<Props> = ({ itemId }) => {
  const dialogs = useCommonDialogs();
  return (
    <>
      <DropdownMenu.Item onClick={() => dialogs?.openItemNotesEditor({ itemId })}>
        <Pencil2Icon />
        Notes
      </DropdownMenu.Item>
    </>
  );
};
