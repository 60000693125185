import * as React from "react";
import { Authenticated, AuthLoading, Unauthenticated } from "convex/react";
import { Box, Flex, Spinner } from "@radix-ui/themes";
import { useIsSm } from "../common/hooks.ts";
import { SideNav } from "../common/nav/side/SideNav.tsx";
import { BottomNav } from "../common/nav/bottom/BottomNav.tsx";
import { Navigate } from "../common/Navigate.tsx";
import { routes } from "../routing/router.ts";
import { Center } from "../common/Center.tsx";
import { LogoIcon } from "../common/LogoIcon.tsx";

interface Props {
  children?: React.ReactNode;
}

export const MainPageLayout: React.FC<Props> = ({ children }) => {
  const isSm = useIsSm();
  return (
    <>
      <Authenticated>
        <Flex position={"relative"} height={"100dvh"} width={"100dvw"}>
          <Box
            data-comment={"darkened background across the top of the page"}
            position={"absolute"}
            top="0"
            left="0"
            right="0"
            height={{ initial: "calc( 40px + var(--safe-area-inset-top, 0px) )", sm: "70px" }}
            style={{
              backgroundColor: "var(--brown-a3)",
              borderBottom: "1px solid var(--brown-a5)",
            }}
          />

          <Box display={{ initial: "none", sm: "block" }}>
            <SideNav />
          </Box>
          <Box display={{ initial: "block", sm: "none" }}>
            <BottomNav />
          </Box>

          <Flex
            direction={"column"}
            flexGrow={"1"}
            position={"relative"}
            pr={{ initial: "2", sm: "4" }}
            pl={{ initial: "2", sm: "0" }}
            pt={{ initial: "calc( 8px + var(--safe-area-inset-top, 0px) )", sm: "5" }}
            align={"stretch"}
            style={{
              overflow: "visible",
              pointerEvents: "none",
            }}
            pb={{ initial: "calc( 75px  + var(--safe-area-inset-bottom, 0px) )", sm: "0" }}
          >
            <Flex
              direction={"column"}
              align={"stretch"}
              style={{
                background: "#fffbf5",
                position: "relative",
                borderRadius: isSm ? "var(--radius-4)" : "var(--radius-4) var(--radius-4) 0 0",
                boxShadow:
                  "rgba(69, 15, 3, 0.12) 0px 0px 8px 2px, rgb(255 255 255) 1px 2px 0px 0px inset",
                border: "1px solid #e9d2b5",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                pointerEvents: "initial",
              }}
            >
              {children}
            </Flex>
          </Flex>
        </Flex>
      </Authenticated>
      <Unauthenticated>
        <Navigate to={routes.root()} />
      </Unauthenticated>
      <AuthLoading>
        <Center position={"relative"} height={"100dvh"} width={"100dvw"}>
          <LogoIcon scale={0.5} />
          <Spinner size={"3"} />
        </Center>
      </AuthLoading>
    </>
  );
};
