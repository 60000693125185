import * as React from "react";
import { Box, Flex } from "@radix-ui/themes";
import { useRoute } from "../../../routing/router.ts";
import { NavItemData } from "../nav.tsx";
import { useCommonDialogs } from "../../CommonDialogsProvider.tsx";

interface Props {
  children?: React.ReactNode;
  item: NavItemData;
}

export const BottomNavItem: React.FC<Props> = ({ children, item }) => {
  const route = useRoute();
  const isSelected = item.getIsSelected({ route });
  const commonDialogs = useCommonDialogs();
  return (
    <Flex
      direction={"column"}
      align={"center"}
      onClick={() => item.onClick?.({ commonDialogs })}
      style={{
        background: isSelected ? "var(--brown-a4)" : "transparent",
        userSelect: "none",
        borderRadius: "0 0 var(--radius-4) var(--radius-4)",
        cursor: "pointer",
        color: isSelected ? "var(--brown-11)" : "var(--black-2)",
        fontSize: "1em",
      }}
      p={"3"}
      pb={"2"}
      width={"80px"}
    >
      <Box style={{ zoom: 1.7, height: "20px" }}>{item.icon}</Box>
      {children}
    </Flex>
  );
};
