import * as React from "react";
import { api } from "@repo/convex/convex/_generated/api";
import { Box, Flex, Heading } from "@radix-ui/themes";
import { PageTopNav } from "../common/PageTopNav.tsx";
import { usePaginatedItems } from "../common/hooks.ts";
import { useState } from "react";
import { AddItemDialog } from "../common/AddItemDialog.tsx";
import { ItemsScrollArea } from "../common/items/ItemsScrollArea.tsx";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { TrashedItemDoc } from "@repo/convex/convex/schema.ts";
import { TrashedItem } from "./TrashedItem.tsx";
import { ListedItem } from "@repo/convex/convex/items.ts";

interface Props {
  children?: React.ReactNode;
}

export const TrashPage: React.FC<Props> = ({ children }) => {
  const { status, loadMore, isLoading, items } = usePaginatedItems({
    statusKind: "trashed",
  });
  const count = useQuery(api.items.count, { statusKind: "trashed" });
  const [isAddOpen, setIsAddOpen] = useState(false);

  return (
    <Flex height={"100%"} direction={"column"}>
      {isAddOpen && <AddItemDialog setIsOpen={setIsAddOpen} />}
      <PageTopNav>
        <Flex align={"stretch"}>
          <Box flexGrow={"1"}>
            <Heading as={"h1"}>Trashed ({count == 500 ? "500+" : count})</Heading>
          </Box>
        </Flex>
      </PageTopNav>
      <ItemsScrollArea
        items={items}
        isLoading={isLoading}
        renderItem={(item) => (
          <TrashedItem key={item._id} item={item as ListedItem<TrashedItemDoc>} />
        )}
        onLoadMore={() => loadMore(20)}
        canLoadMore={status == "CanLoadMore"}
      />
    </Flex>
  );
};
