import * as React from "react";
import { Button, Flex } from "@radix-ui/themes";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { Section } from "../common/section/Section.tsx";
import { useAction, useMutation, useQuery } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { toast } from "sonner";

interface Props {
  itemId: Id<"items">;
}

export const ItemDebugSection: React.FC<Props> = ({ itemId }) => {
  const updateProfileFromItemRead = useMutation(
    api.userProfiles.debugUpdateUserProfileFromItemRead,
  );
  const cleanScrapedContent = useAction(
    api.itemContent.user.debugActions.cleanScrapedMarkdownForItemContent,
  );
  const content = useQuery(api.itemContent.user.queries.findMineForItem, { itemId });
  return (
    <Section title={"Debug actions"}>
      <Flex direction={"row"} gap={"2"} wrap={"wrap"}>
        <Button disabled={true} onClick={() => {}}>
          Generate Item Notes Embeddings
        </Button>
        <Button onClick={() => updateProfileFromItemRead({ itemId }).catch(toast.promise)}>
          Update User Profile From Item Read
        </Button>
        <Button
          onClick={() =>
            content
              ? cleanScrapedContent({ itemContentId: content._id }).catch(toast.promise)
              : null
          }
        >
          Clean scraped content
        </Button>
      </Flex>
    </Section>
  );
};
