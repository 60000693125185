import * as React from "react";
import { AlertDialog, Button, Flex } from "@radix-ui/themes";

interface Props {
  onOpenChange: (isOpen: boolean) => unknown;
  onConfirm: () => unknown;
}

export const ConfirmDeleteDialog: React.FC<Props> = ({ onOpenChange, onConfirm }) => {
  return (
    <AlertDialog.Root open={true} onOpenChange={onOpenChange}>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Permanently Delte</AlertDialog.Title>
        <AlertDialog.Description size="2">
          Are you sure you want to delete this? This is not reversible.
        </AlertDialog.Description>
        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button variant="solid" color="red" onClick={onConfirm}>
              Delete
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
