import * as React from "react";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  linkPlugin,
  linkDialogPlugin,
  markdownShortcutPlugin,
  MDXEditorMethods,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { Box, Link } from "@radix-ui/themes";
import "./ItemNotesEditor.css";
import { useEffect } from "react";

interface Props {
  notes: string;
  setNotes: (notes: string) => unknown;
}

export const ItemNotesEditor: React.FC<Props> = ({ notes, setNotes }) => {
  const mdxEditorRef = React.useRef<MDXEditorMethods>(null);

  useEffect(() => {
    if (!mdxEditorRef.current) return;
    if (!notes) return;
    mdxEditorRef.current.setMarkdown(notes);
  }, [notes]);

  return (
    <Box style={{ border: "1px solid var(--brown-a8)", borderRadius: "6px", overflow: "hidden" }}>
      <MDXEditor
        ref={mdxEditorRef}
        className={"ItemNotesEditor"}
        contentEditableClassName={"ItemNotesEditor-content"}
        markdown={notes}
        placeholder={`A great article about AI, this aligns with my thoughts on..`}
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          linkPlugin(),
          linkDialogPlugin(),
          quotePlugin(),
          diffSourcePlugin({ viewMode: "rich-text" }),
          markdownShortcutPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <DiffSourceToggleWrapper options={["source", "rich-text"]}>
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                </DiffSourceToggleWrapper>
              </>
            ),
          }),
        ]}
        onChange={setNotes}
      />
    </Box>
  );
};
