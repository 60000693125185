import * as React from "react";
import { Box, Button, Card, Flex, ScrollArea, Skeleton, Text } from "@radix-ui/themes";
import { iife, sequence } from "@repo/shared/utils/misc";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Center } from "../Center.tsx";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { scrollToTopSignal } from "../nav/nav.tsx";

interface Props<T> {
  items: T[] | undefined;
  isLoading?: boolean;
  onAddItem?: () => void;
  renderItem: (item: T) => React.ReactNode;
  onLoadMore?: () => void;
  canLoadMore?: boolean;
}

export function ItemsScrollArea<T>({
  items,
  onAddItem,
  renderItem,
  canLoadMore,
  isLoading,
  onLoadMore,
}: Props<T>) {
  const [parent] = useAutoAnimate();

  const { ref, inView, entry } = useInView({
    delay: 500,
  });

  const scrollAreaRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!inView) return;
    if (!canLoadMore) return;
    onLoadMore?.();
  }, [inView, canLoadMore]);

  useEffect(() => {
    return scrollToTopSignal.add(() =>
      scrollAreaRef.current?.scrollTo({ top: 0, behavior: "smooth" }),
    );
  }, []);

  return (
    <ScrollArea
      ref={scrollAreaRef}
      type="auto"
      scrollbars="vertical"
      style={{ width: "100%", overflow: "hidden" }}
    >
      {iife(() => {
        if (isLoading || !items)
          return (
            <Flex
              gap={"2"}
              flexShrink={"1"}
              wrap={"wrap"}
              p={"4"}
              maxWidth={"100%"}
              overflow={"hidden"}
            >
              {sequence(20).map((index) => (
                <Skeleton
                  key={index}
                  loading={true}
                  width={{ initial: "calc(100dvw - 55px)", xs: "280px" }}
                  height="180px"
                />
              ))}
            </Flex>
          );

        if (items.length == 0)
          return (
            <Center width={"100%"} height={"100%"}>
              <Card size={"3"}>
                <Flex direction={"column"} align={"center"}>
                  <MagnifyingGlassIcon />
                  <Text>No Items</Text>
                  {onAddItem ? <Button onClick={onAddItem}>Add One</Button> : null}
                </Flex>
              </Card>
            </Center>
          );

        return (
          <Flex direction={"column"} align={"stretch"}>
            <Flex
              ref={parent}
              gap={"2"}
              flexShrink={"1"}
              wrap={"wrap"}
              p={"4"}
              maxWidth={"100%"}
              overflow={"hidden"}
              justify={{ initial: "center", sm: "start" }}
            >
              {items.map(renderItem)}
            </Flex>

            <Flex mt={"5"} width={"100%"} align={"center"} justify={"center"} p={"2"} ref={ref}>
              <Button
                variant={canLoadMore ? "solid" : "ghost"}
                style={{}}
                onClick={onLoadMore}
                disabled={!canLoadMore}
              >
                {canLoadMore ? `Load More` : `No more items!`}
              </Button>
            </Flex>
          </Flex>
        );
      })}
    </ScrollArea>
  );
}
