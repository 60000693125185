import * as React from "react";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { Button, Callout } from "@radix-ui/themes";
import { ExclamationTriangleIcon, ResetIcon } from "@radix-ui/react-icons";
import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { toast } from "sonner";
import { useOptimisticArchiveItem } from "../common/hooks.ts";
import { ItemCard } from "../common/items/ItemCard.tsx";
import { TrashedItemDoc } from "@repo/convex/convex/schema.ts";
import { ItemMenu } from "../common/items/ItemMenu.tsx";
import { PermanentlyDeleteMenuItem } from "./PermanentlyDeleteMenuItem.tsx";
import { CommonMenuItems } from "../common/items/menu/CommonMenuItems.tsx";
import { ItemNotesActionButton } from "../common/items/ItemNotesActionButton.tsx";
import { ItemActions } from "../common/items/ItemActions.tsx";
import { ListedItem } from "@repo/convex/convex/items.ts";

interface Props {
  item: ListedItem<TrashedItemDoc>;
}

export const TrashedItem: React.FC<Props> = ({ item }) => {
  const archive = useOptimisticArchiveItem();
  const restore = useMutation(api.items.restoreMine);
  const trash = useMutation(api.items.trash);

  return (
    <ItemCard
      item={item}
      actions={
        <ItemActions>
          <Button
            variant={"surface"}
            onClick={(e) => {
              restore({ itemId: item._id })
                .then(() =>
                  toast.success(`Item restored to ${item.status.restoreToStatus.kind}`, {
                    action: {
                      label: "Undo",
                      onClick: () => trash({ itemId: item._id }).catch(toast.error),
                    },
                  }),
                )
                .catch(console.error);

              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ResetIcon /> Restore
          </Button>
          {item.hasNotes ? <ItemNotesActionButton itemId={item._id} /> : null}
        </ItemActions>
      }
      menu={
        <ItemMenu>
          <CommonMenuItems item={item} />
          <PermanentlyDeleteMenuItem item={item} />
        </ItemMenu>
      }
    >
      <Callout.Root variant="soft" size={"1"} color={"red"} mt={"2"}>
        <Callout.Icon>
          <ExclamationTriangleIcon />
        </Callout.Icon>
        <Callout.Text>
          This item will be permanently deleted{" "}
          {formatDistance(item.status.willBeDeletedAt, new Date(), { addSuffix: true })}
        </Callout.Text>
      </Callout.Root>
    </ItemCard>
  );
};
