"use client";
import * as React from "react";
import { useEffect } from "react";
import { SafeArea, SafeAreaInsets } from "capacitor-plugin-safe-area";
interface Props {
  children?: React.ReactNode;
}

export const SafeAreasActivator: React.FC<Props> = ({}) => {
  useEffect(() => {
    const onSafeAreaChanged = (data: SafeAreaInsets) => {
      console.log("got safe area insets", data);
      const { insets } = data;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`);
      }
    };

    SafeArea.getSafeAreaInsets().then(onSafeAreaChanged).catch(console.error);

    SafeArea.getStatusBarHeight()
      .then(({ statusBarHeight }) => {
        console.log(statusBarHeight, "statusbarHeight");
      })
      .catch(console.error);

    SafeArea.addListener("safeAreaChanged", onSafeAreaChanged);

    return () => {
      SafeArea.removeAllListeners().catch(console.error);
    };
  }, []);

  return null;
};
