import * as React from "react";
import { Skeleton } from "@radix-ui/themes";
import { ItemNotesEditor } from "./ItemNotesEditor.tsx";
import { useEffect } from "react";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { useConvex, useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { toast } from "sonner";

interface Props {
  itemId: Id<"items"> | null;
  onSaveStateChange?: (state: SaveState) => unknown;
}

export type SaveState =
  | {
      kind: "loading";
    }
  | {
      kind: "loaded";
      notes: string;
    }
  | {
      kind: "saving";
      notes: string;
    }
  | {
      kind: "saved";
      at: number;
      notes: string;
    };

export const ConnectedNotesEditor: React.FC<Props> = ({ onSaveStateChange, itemId }) => {
  const [notes, setNotes] = React.useState<string>("");
  const [saveState, _setSaveState] = React.useState<SaveState>({ kind: "loading" });

  const setSaveState = (state: SaveState) => {
    _setSaveState(state);
    onSaveStateChange?.(state);
  };

  useLoadInitialData({
    itemId,
    setSaveState,
    setNotes,
  });

  useSaveOnUnmountOrItemChange({
    itemId,
    saveState,
    notes,
  });

  useDebounceAndSave({
    saveState,
    notes,
    itemId,
    setSaveState,
  });

  return (
    <>
      {/*{saveState.kind == "loading" ? (*/}
      {/*  <Skeleton loading={true} width={"100%"} height="200px" />*/}
      {/*) : (*/}
      {/*  <ItemNotesEditor notes={notes} setNotes={setNotes} />*/}
      {/*)}*/}
      <ItemNotesEditor notes={notes} setNotes={setNotes} />
    </>
  );
};

const useLoadInitialData = ({
  setSaveState,
  itemId,
  setNotes,
}: {
  setSaveState: (state: SaveState) => unknown;
  setNotes: (notes: string) => unknown;
  itemId: Id<"items"> | null;
}) => {
  const client = useConvex();

  // Grab the initial data
  useEffect(() => {
    if (!itemId) return;

    console.log("Loading notes for item", itemId);
    setSaveState({ kind: "loading" });
    setNotes("");

    client
      .query(api.itemNotes.findMineForItem, { itemId })
      .then((doc) => {
        const notes = doc?.notes ?? "";
        console.log("Loaded notes for item", { itemId, notes });
        setNotes(notes);
        setSaveState({ kind: "loaded", notes });
      })
      .catch(toast.error);
  }, [itemId]);
};

const useDebounceAndSave = ({
  notes,
  saveState,
  itemId,
  setSaveState,
}: {
  saveState: SaveState;
  setSaveState: (state: SaveState) => unknown;
  itemId: Id<"items"> | null;
  notes: string;
}) => {
  const save = useMutation(api.itemNotes.save);

  useEffect(() => {
    if (saveState.kind == "loading") return;
    if (saveState.kind == "saving") return;
    if (saveState.kind == "saved" && notes == saveState.notes) return;
    if (saveState.kind == "loaded" && notes == saveState.notes) return;
    if (!itemId) return;

    const id = setTimeout(() => {
      setSaveState({ kind: "saving", notes });
      console.log("Debounced timeout, saving notes for item", { itemId, notes });
      save({ itemId, notes })
        .then(() => {
          setSaveState({ kind: "saved", at: Date.now(), notes });
        })
        .catch((e) => {
          toast.error(e);
          setSaveState({ kind: "saved", at: Date.now(), notes });
        });
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [saveState.kind, notes, setSaveState, itemId]);
};

const useSaveOnUnmountOrItemChange = ({
  saveState,
  itemId,
  notes,
}: {
  saveState: SaveState;
  itemId: Id<"items"> | null;
  notes: string;
}) => {
  const save = useMutation(api.itemNotes.save);

  // We need to ensure that the effect always has the latest value
  const notesRef = React.useRef(notes);
  const saveStateRef = React.useRef(saveState);
  notesRef.current = notes;
  saveStateRef.current = saveState;

  useEffect(() => {
    if (!itemId) return;
    return () => {
      const _state = saveStateRef.current;
      const _notes = notesRef.current;

      // Dont bother if we are still loading or havent changed yet
      if (_state.kind == "loading") return;

      // Dont bother if the notes is the same
      if (_state.kind == "loaded" && _state.notes == _notes) return;
      if (_state.kind == "saved" && _state.notes == _notes) return;
      if (_state.kind == "saving" && _state.notes == _notes) return;

      console.log("Item change or unmounting, saving notes..", {
        itemId,
        notes: notesRef.current,
      });

      // Save the latest value
      save({ itemId, notes: notesRef.current }).catch(toast.error);
    };
  }, [itemId]);
};
