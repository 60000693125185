import * as React from "react";
import { IconButton, IconButtonProps } from "@radix-ui/themes";
import { Cross2Icon } from "@radix-ui/react-icons";

interface Props extends IconButtonProps {}

export const DialogCloseButton: React.FC<Props> = ({ children, style, ...rest }) => {
  return (
    <IconButton
      variant={"ghost"}
      style={{
        position: "absolute",
        top: "15px",
        right: "15px",
        color: "var(--brown-a6)",
        ...style,
      }}
      {...rest}
    >
      <Cross2Icon width={"20px"} height={"20px"} />
      {children}
    </IconButton>
  );
};
