import * as React from "react";
import { Doc } from "@repo/convex/convex/_generated/dataModel";
import { Badge } from "@radix-ui/themes";
import { exhaustiveCheck } from "@repo/shared/utils/misc.ts";

interface Props {
  profile: Doc<"userProfiles">;
}

export const UserProfileStatusBadge: React.FC<Props> = ({ profile }) => {
  // if (profile.status.kind == "created") return <Badge color="green">Created</Badge>;
  // if (profile.status.kind == "updated") return <Badge color="green">Updated</Badge>;
  // if (profile.status.kind == "update_requested")
  //   return <Badge color="blue">Update Requested</Badge>;
  // if (profile.status.kind == "update_error") return <Badge color="red">Update Errored</Badge>;
  // exhaustiveCheck(profile.status);
  return null;
};
