import * as React from "react";
import { Button, DropdownMenu, IconButton } from "@radix-ui/themes";
import { DotsHorizontalIcon, TrashIcon } from "@radix-ui/react-icons";
import { Doc } from "@repo/convex/convex/_generated/dataModel";

interface Props {
  children?: React.ReactNode;
}

export const ItemMenu: React.FC<Props> = ({ children }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <IconButton variant={"ghost"}>
          <DotsHorizontalIcon width={18} height={18} />
        </IconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content style={{ minWidth: "150px" }}>{children}</DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
