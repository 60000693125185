import * as React from "react";
import { Section } from "../../common/section/Section.tsx";
import { Badge, Box, DataList, Flex, Table, TextArea } from "@radix-ui/themes";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { api } from "@repo/convex/convex/_generated/api";
import { useDebounce } from "../../common/hooks.ts";
import { useMutation } from "convex/react";
import { toast } from "sonner";
import { useEffect } from "react";
import { UserProfileStatusBadge } from "./UserProfileStatusBadge.tsx";
import { TokenUsageTable } from "./TokenUsageTable.tsx";
import { QueuedUpdatesList } from "./QueuedUpdatesList.tsx";
import { SectionTitle } from "../../common/section/SectionTitle.tsx";

interface Props {}

export const MyProfileSection: React.FC<Props> = ({}) => {
  const profileDoc = useQuery(api.userProfiles.findMine, {});
  const [profile, setProfile] = React.useState<string | null>(null);
  const [isSending, setIsSending] = React.useState(false);
  const updateMyProfile = useMutation(api.userProfiles.updateMyProfile);

  useEffect(() => {
    if (isSending) return;
    setProfile(profileDoc?.profile ?? null);
  }, [profileDoc, isSending]);

  const _updateProfile = useDebounce((profile: string) => {
    console.log("updating profile..");
    setIsSending(true);
    updateMyProfile({ profile })
      .catch(toast.error)
      .finally(() => setIsSending(false));
  }, 500);

  return (
    <Section
      title={"My Profile"}
      titleRight={profileDoc ? <UserProfileStatusBadge profile={profileDoc} /> : undefined}
    >
      {profileDoc && <TokenUsageTable profileDoc={profileDoc} />}
      <TextArea
        value={profile ?? ""}
        resize="vertical"
        placeholder="Your profile will go here"
        style={{ minHeight: "400px" }}
        onChange={(e) => {
          _updateProfile(e.target.value);
          setProfile(e.target.value);
        }}
      />

      {profileDoc && (
        <Flex direction={"column"}>
          <Box mb={"2"}>
            <SectionTitle>Queued Updates</SectionTitle>
          </Box>
          <QueuedUpdatesList />
        </Flex>
      )}
    </Section>
  );
};
