import * as React from "react";
import { Table } from "@radix-ui/themes";
import { Doc } from "@repo/convex/convex/_generated/dataModel";

interface Props {
  profileDoc: Doc<"userProfiles">;
}

const costPerMillionInputTokensInDollars = 0.15;
const costPerMillionOutputTokensInDollars = 0.6;
const calculateCost = (tokens: number, costPerMillion: number) =>
  (tokens / 1000000) * costPerMillion;

export const TokenUsageTable: React.FC<Props> = ({ profileDoc }) => {
  const promptTokensUsed = profileDoc.promptTokensUsed ?? 0;
  const completionTokensUsed = profileDoc.completionTokensUsed ?? 0;
  const totalTokensUsed = profileDoc.totalTokensUsed ?? 0;
  const promptCost = calculateCost(promptTokensUsed, costPerMillionInputTokensInDollars);
  const completionCost = calculateCost(completionTokensUsed, costPerMillionOutputTokensInDollars);
  const totalCost = promptCost + completionCost;

  return (
    <Table.Root variant="surface" size={"1"}>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Value</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Estimated Cost</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.RowHeaderCell>Prompt Tokens Used</Table.RowHeaderCell>
          <Table.Cell>{promptTokensUsed}</Table.Cell>
          <Table.Cell>${promptCost.toFixed(4)}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeaderCell>Completion Tokens Used</Table.RowHeaderCell>
          <Table.Cell>{completionTokensUsed}</Table.Cell>
          <Table.Cell>${completionCost.toFixed(4)}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeaderCell>Total Tokens Used</Table.RowHeaderCell>
          <Table.Cell>{totalTokensUsed}</Table.Cell>
          <Table.Cell>${totalCost.toFixed(4)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table.Root>
  );
};
