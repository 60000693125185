import * as React from "react";
import { Doc } from "@repo/convex/convex/_generated/dataModel";
import { Callout, Link, Spinner } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import Markdown from "react-markdown";

interface Props {
  message: Doc<"itemChatMessages">;
}

export const ChatMessageContent: React.FC<Props> = ({ message }) => {
  if (message.status.kind == "message_completion_requested") return <Spinner />;

  if (message.status.kind == "message_completion_errored")
    return (
      <Callout.Root color="red">
        <Callout.Icon>
          <InfoCircledIcon />
        </Callout.Icon>
        <Callout.Text>
          Message completion errored. {message.status.error}.{" "}
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            attempt to regenerate it now
          </Link>
        </Callout.Text>
      </Callout.Root>
    );

  return <Markdown>{message.message}</Markdown>;
};
