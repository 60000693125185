import * as React from "react";
import { Dialog, Flex, Spinner } from "@radix-ui/themes";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { DialogCloseButton } from "../../common/DialogCloseButton.tsx";
import { Center } from "../../common/Center.tsx";
import { useGetOrCreateItemChats } from "../itemChats.ts";
import { ChatMessagesList } from "./ChatMessagesList.tsx";
import { ChatMessageEntryBox } from "./ChatMessageEntryBox.tsx";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { api } from "@repo/convex/convex/_generated/api";

interface Props {
  itemId: Id<"items"> | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => unknown;
}

export const ItemChatDialog: React.FC<Props> = ({ itemId, isOpen, setIsOpen }) => {
  const { chat, isLoading } = useGetOrCreateItemChats(itemId);
  const item = useQuery(api.items.findMine, itemId ? { itemId } : "skip");

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!itemId) return;
        setIsOpen(isOpen);
      }}
    >
      <Dialog.Content
        align={"start"}
        style={{
          marginTop: "calc(var(--safe-area-inset-top, 20px) + 30px)",
          backgroundColor: "var(--brown-2)",
        }}
      >
        <Dialog.Title mb={"0"}>Item Chat</Dialog.Title>
        <Dialog.Description color={"gray"} size={"2"} mb={"3"}>
          {item?.title}
        </Dialog.Description>

        <DialogCloseButton onClick={() => setIsOpen(false)} />

        <Flex
          direction="column"
          gap="3"
          align={"stretch"}
          justify={"end"}
          style={{
            border: "1px solid var(--brown-5)",
            minHeight: "200px",
            backgroundColor: "white",
            borderRadius: "var(--radius-4)",
          }}
        >
          {chat && (
            <Flex direction="column" gap="3">
              <ChatMessagesList chatId={chat._id} />
              <ChatMessageEntryBox chatId={chat._id} />
            </Flex>
          )}
        </Flex>

        {chat == null ? (
          <Center style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}>
            <Spinner size={"3"} />
          </Center>
        ) : null}
      </Dialog.Content>
    </Dialog.Root>
  );
};
