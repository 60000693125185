import { api } from "@repo/convex/convex/_generated/api";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { useEffect } from "react";
import { toast } from "sonner";
import { useMutation, useQuery } from "convex/react";

export const useGetOrCreateItemChats = (itemId: Id<"items"> | null) => {
  const chat = useQuery(api.itemChats.findMyChatForItem, itemId ? { itemId } : "skip");
  const createChat = useMutation(api.itemChats.createMyChatForItem);

  useEffect(() => {
    if (!itemId) return;
    if (chat === undefined) return;
    if (chat) return;
    console.log(`Creating chat for item ${itemId}`);
    createChat({ itemId }).catch(toast.error);
  }, [itemId, chat]);

  return {
    isLoading: chat === undefined,
    chat,
  };
};
