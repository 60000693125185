import * as React from "react";
import { Flex, FlexProps } from "@radix-ui/themes";

type Props = {
  children?: React.ReactNode;
} & FlexProps;

export const Center: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex direction={"column"} align={"center"} justify={"center"} {...rest}>
      {children}
    </Flex>
  );
};
