import * as React from "react";
import { Box, Flex, Text } from "@radix-ui/themes";
import { SectionTitle } from "./SectionTitle.tsx";

interface Props {
  children?: React.ReactNode;
  title: string;
  titleRight?: React.ReactNode;
}

export const Section: React.FC<Props> = ({ children, title, titleRight }) => {
  return (
    <Flex
      direction={"column"}
      align={"stretch"}
      gap={"2"}
      style={{ border: "1px dashed var(--brown-a4)", borderRadius: "4px" }}
      p={"2"}
    >
      <SectionTitle titleRight={titleRight}>{title}</SectionTitle>
      {children}
    </Flex>
  );
};
