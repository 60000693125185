import { useMediaQuery } from "react-responsive";
import {
  optimisticallyUpdateValueInPaginatedQuery,
  PaginatedQueryArgs,
  useMutation,
  usePaginatedQuery,
} from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";
import { useEffect, useRef, useState } from "react";

export const useIsSm = () => useMediaQuery({ query: "(max-width: 768px)" });

export const useOptimisticArchiveItem = () => {
  return useMutation(api.items.archiveMine).withOptimisticUpdate((localStore, args) => {
    const { itemId } = args;
    optimisticallyUpdateValueInPaginatedQuery(
      localStore,
      api.items.list,
      { statusKind: "unread" },
      (currentValue) => {
        if (itemId === currentValue._id) {
          return {
            ...currentValue,
            status: { archivedAt: Date.now(), kind: "archived" as const },
          };
        }
        return currentValue;
      },
    );
  });
};

export const useOptimisticUnArchiveItem = () => {
  return useMutation(api.items.unarchiveMine).withOptimisticUpdate((localStore, args) => {
    const { itemId } = args;
    optimisticallyUpdateValueInPaginatedQuery(
      localStore,
      api.items.list,
      { statusKind: "archived" },
      (currentValue) => {
        if (itemId === currentValue._id) {
          return {
            ...currentValue,
            status: { archivedAt: Date.now(), kind: "unread" as const },
          };
        }
        return currentValue;
      },
    );
  });
};

export const useOptimisticTrashItem = () => {
  return useMutation(api.items.trash).withOptimisticUpdate((localStore, args) => {
    const { itemId } = args;
    optimisticallyUpdateValueInPaginatedQuery(
      localStore,
      api.items.list,
      { statusKind: "trashed" },
      (currentValue) => {
        if (itemId === currentValue._id && currentValue.status.kind == "trashed") {
          return {
            ...currentValue,
            status: currentValue.status.restoreToStatus,
          };
        }
        return currentValue;
      },
    );
  });
};

export const usePaginatedItems = (args: PaginatedQueryArgs<typeof api.items.list>) => {
  const query = usePaginatedQuery(api.items.list, args, {
    initialNumItems: 20,
  });
  return {
    ...query,
    items: query.results.filter((item) => item.status.kind === args.statusKind),
  };
};

// Define a generic hook to support different types of values
export const useThrottle = <T>(value: T, limit: number = 500): T => {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  const lastRan = useRef<number>(Date.now());

  useEffect(() => {
    const handler = setTimeout(
      () => {
        if (Date.now() - lastRan.current >= limit) {
          setThrottledValue(value);
          lastRan.current = Date.now();
        }
      },
      limit - (Date.now() - lastRan.current),
    );

    return () => {
      clearTimeout(handler);
    };
  }, [value, limit]);

  return throttledValue;
};

export function useDebounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const debouncedFunction = (...args: Parameters<T>): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, wait);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedFunction;
}
