import * as React from "react";
import { DropdownMenu } from "@radix-ui/themes";
import { Link2Icon, Pencil2Icon } from "@radix-ui/react-icons";
import { Doc, Id } from "@repo/convex/convex/_generated/dataModel";
import { useCommonDialogs } from "../../CommonDialogsProvider.tsx";
import { useCopyToClipboard } from "react-use";
import { toast } from "sonner";

interface Props {
  itemUrl: string;
}

export const CopyLinkMenuItem: React.FC<Props> = ({ itemUrl }) => {
  const [, copy] = useCopyToClipboard();
  return (
    <>
      <DropdownMenu.Item
        onClick={() => {
          copy(itemUrl);
          toast.success("Link copied to clipboard");
        }}
      >
        <Link2Icon />
        Copy Link
      </DropdownMenu.Item>
    </>
  );
};
