import { useMutation } from "convex/react";
import { api } from "@repo/convex/convex/_generated/api";

export const optimisticallyUpdateMySettings = () => {
  return useMutation(api.userSettings.updateMySettings).withOptimisticUpdate((localStore, args) => {
    const currentValue = localStore.getQuery(api.userSettings.getMineOrDefault);
    if (!currentValue) return;
    localStore.setQuery(
      api.userSettings.getMineOrDefault,
      {},
      {
        settings: {
          ...currentValue.settings,
          ...args,
        },
      },
    );
  });
};
