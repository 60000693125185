import * as React from "react";
import { EditNotesMenuItem } from "./EditNotesMenuItem.tsx";
import { Doc } from "@repo/convex/convex/_generated/dataModel";
import { DropdownMenu } from "@radix-ui/themes";
import { ItemDetailsMenuItem } from "./ItemDetailsMenuItem.tsx";
import { CopyLinkMenuItem } from "./CopyLinkMenuItem.tsx";
import { ItemChatMenuItem } from "./ItemChatMenuItem.tsx";

interface Props {
  item: Doc<"items">;
}

export const CommonMenuItems: React.FC<Props> = ({ item }) => {
  return (
    <>
      <CopyLinkMenuItem itemUrl={item.url} />
      <ItemDetailsMenuItem itemId={item._id} />
      <EditNotesMenuItem itemId={item._id} />
      <ItemChatMenuItem itemId={item._id} />
      <DropdownMenu.Separator />
    </>
  );
};
