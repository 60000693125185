import * as React from "react";
import { defaultHeaderImageUrl, ItemCard } from "../common/items/ItemCard.tsx";
import { SearchResult } from "@repo/convex/convex/search.ts";
import { Box, Card, Flex, Inset, Text } from "@radix-ui/themes";

interface Props {
  item: SearchResult["item"];
}

export const SearchItem: React.FC<Props> = ({ item }) => {
  return (
    <Flex
      direction={"row"}
      gap="4"
      style={{
        border: "1px solid var(--gray-5)",
        overflow: "hidden",
        borderRadius: "var(--radius-4)",
        cursor: "pointer",
      }}
      onClick={() => {
        window.open(item.url, "_blank");
      }}
    >
      <img
        src={item.headerImageUrl || defaultHeaderImageUrl}
        alt="Item img"
        style={{
          display: "block",
          objectFit: "cover",
          width: "80px",
          height: `80px`,
          backgroundColor: "var(--gray-5)",
          flexShrink: 0,
        }}
      />
      <Flex flexGrow={"1"} direction={"column"} style={{ overflow: "hidden" }} pr={"4"} pt={"2"}>
        <Text
          weight="bold"
          style={{
            display: "block",
            overflow: "hidden",
            textWrap: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {item.title}
        </Text>
        <Text as="div" color="gray" size={"1"}>
          {item.url}
        </Text>
      </Flex>
    </Flex>
  );
};
